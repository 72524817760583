import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { fileType } from "app/Enumerations/file-type.enum";
import { Language } from "app/Models/language";
import { Media } from "app/Models/media";
import { MediaAssociation, MediaAssociationTranslation } from "app/Models/module";
import { DataService } from "app/Services/Data-Service/data.service";
import { ModuleService } from "app/Services/Module-Service/module.service";
import { CompletionStateComponent } from "../../../ReusableComponents/completion-state/completion-state.component";
import { AssociatedMediaListComponent } from "../associated-media-list/associated-media-list.component";
import { MediaAssociationComponent } from "../../../Popups/media-association/media-association.component";

@Component({
  selector: "app-module-gallery",
  templateUrl: "./module-gallery.component.html",
  styleUrl: "./module-gallery.component.css",
  imports: [CompletionStateComponent, AssociatedMediaListComponent, MediaAssociationComponent],
})
export class ModuleGalleryComponent implements OnInit {
  @Input() languagesSelected: Language[] = [];
  @Input() mediaList: Media[] = [];
  associatedMediaList: MediaAssociation[] = [];

  indexPopupOpened: number = -1;

  mediaVisualisationFromPage: boolean = false;

  fileBlob?: Blob;
  fileUrl?: string;

  isPopupVisible: boolean = false;

  //Forms
  @Input() multiLanguageForm!: FormGroup;

  distanceBetweenCards: number = 50;

  constructor(
    private dataService: DataService,
    private moduleService: ModuleService,
  ) {}

  ngOnInit() {
    this.associatedMediaList = this.moduleService.getMediaAssociations();
  }

  showPopupNewAssociation() {
    this.indexPopupOpened = this.associatedMediaList.length;
    this.isPopupVisible = true;
    this.mediaVisualisationFromPage = false;
  }

  showPopupVisualisation(index: number, mediaVisualisationFromPage: boolean) {
    this.indexPopupOpened = index;
    this.isPopupVisible = true;
    if (mediaVisualisationFromPage === true) {
      this.mediaVisualisationFromPage = mediaVisualisationFromPage;

      if (this.associatedMediaList[index].media.fileType === fileType.Image || this.associatedMediaList[index].media.fileType === fileType.Pdf) {
        this.dataService.getFile(this.associatedMediaList[index].media).subscribe({
          next: (file) => {
            this.fileBlob = file;
            this.fileUrl = URL.createObjectURL(file);
          },
          error: (error) => {
            console.log(error.message);
          },
        });
      } else {
        this.fileUrl = this.dataService.streamFile(this.associatedMediaList[index].media);
      }
    }
  }

  associateMedia(media: Media) {
    const mediaAssociationTranslations: MediaAssociationTranslation[] = [];
    this.languagesSelected.forEach((language) => {
      mediaAssociationTranslations.push(new MediaAssociationTranslation(0, language.id, ""));
    });

    const newMediaAssociation: MediaAssociation = new MediaAssociation(0, media, false, this.associatedMediaList.length + 1, false, mediaAssociationTranslations);

    this.moduleService.associateMedia(newMediaAssociation);
    this.closePopup();
  }

  removeMedia(index: number) {
    for (let i = 0; i < this.associatedMediaList.length; i++) {
      if (i > index) {
        this.associatedMediaList[i - 1] = this.associatedMediaList[i];
        this.associatedMediaList[i].order -= 1;
      }
    }

    this.associatedMediaList.splice(this.associatedMediaList.length - 1, 1);
    this.moduleService.setMediaAssociations(this.associatedMediaList);
  }

  closePopup() {
    this.isPopupVisible = false;
    this.mediaVisualisationFromPage = false;
    this.indexPopupOpened = -1;
    this.fileBlob = undefined;
    this.fileUrl = undefined;
  }

  getHeightCard(): number {
    if (this.associatedMediaList && this.associatedMediaList.length > 0) {
      return this.associatedMediaList.length * this.distanceBetweenCards + 80;
    } else {
      return 90;
    }
  }

  saveMediaNameTranslation(event: MediaAssociationTranslation) {
    // The property translatedEntityId is used to pass the index of the mediaAssociation to the parent component
    const indexLanguage = this.associatedMediaList[event.translatedEntityId].mediaAssociationTranslations?.findIndex((mt) => mt.languageId === event.languageId);
    if (indexLanguage !== -1 && this.associatedMediaList[event.translatedEntityId].mediaAssociationTranslations[indexLanguage]) {
      this.associatedMediaList[event.translatedEntityId].mediaAssociationTranslations[indexLanguage].name = event.name;
      this.moduleService.setMediaAssociations(this.associatedMediaList);
    }
  }

  saveMediaAssociationInfos(mediaAssociation: MediaAssociation) {
    this.associatedMediaList[mediaAssociation.order].isMediaNameTranslated = mediaAssociation.isMediaNameTranslated;
    this.associatedMediaList[mediaAssociation.order].is360 = mediaAssociation.is360;
    this.moduleService.setMediaAssociations(this.associatedMediaList);
  }
}
