import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { moduleType } from "app/Enumerations/module-type.enum";
import { Language } from "app/Models/language";
import { Answer, Module } from "app/Models/module";
import { DataService } from "app/Services/Data-Service/data.service";
import { NgClass } from "@angular/common";
import { ProgressSpinner } from "primeng/progressspinner";
import { InformationPopupComponent } from "../../../../Popups/previsualisation/information-popup/information-popup.component";
import { ExplanationPopupComponent } from "../../../../Popups/previsualisation/explanation-popup/explanation-popup.component";

@Component({
  selector: "app-quiz-inc-previsualisation",
  templateUrl: "./quiz-inc-previsualisation.component.html",
  styleUrl: "./quiz-inc-previsualisation.component.css",
  imports: [NgClass, ProgressSpinner, InformationPopupComponent, ExplanationPopupComponent],
})
export class QuizIncPrevisualisationComponent implements OnChanges {
  @Input() selectedLanguageId?: number;
  @Input() entityId?: number;
  @Input() languageList?: Language[] = [];

  @Output() languagesSelectedChanged: EventEmitter<Language[]> = new EventEmitter<Language[]>();
  @Output() entityTypeChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() entityIdChanged: EventEmitter<number> = new EventEmitter<number>();

  module?: Module;
  languagesSelected?: Language[] = [];
  currentQuestion: number = 1;
  highestQuestion: number = 1;
  selectedAnswerId?: number;
  selectedAnswerList: number[] = [];
  resultsVisualisation: boolean = false;
  synthesisVisualisation: boolean = false;
  isInformationPopupVisible: boolean = false;
  incAnswerRandomIndexList: number[] = [];

  questionFileUrls: string[] = [];
  answerFileUrls: string[][] = [];
  synthesisFileUrl: string = "";

  explanationPopupVisible: boolean = false;
  selectedQuestion?: string;
  selectedExplanation?: string;
  selectedFileUrl?: string;

  enumModuleType = moduleType;

  constructor(private dataService: DataService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["entityId"] && this.entityId) {
      this.dataService.getModule(this.entityId.toString()).subscribe((module) => {
        this.module = module;
        this.languagesSelectedChanged.emit(this.setLanguagesSelected());
        this.isInformationPopupVisible = true;
        for (let i = 0; i < this.module!.questions.length; i++) {
          if (this.module && this.module.questions[i] && this.module.questions[i].media) {
            this.dataService.getFile(this.module.questions[i].media!).subscribe({
              next: (file) => {
                this.questionFileUrls[i] = URL.createObjectURL(file);
              },
              error: (error) => {
                console.log(error.message);
              },
            });
          } else {
            this.questionFileUrls[i] = "missing";
          }

          if (!this.answerFileUrls[i]) {
            this.answerFileUrls[i] = [];
          }
          for (let j = 0; j < this.module!.questions[i].answers.length; j++) {
            if (this.module!.questions[i].answers[j].media) {
              this.dataService.getFile(this.module!.questions[i].answers[j].media!).subscribe({
                next: (file) => {
                  this.answerFileUrls[i][j] = URL.createObjectURL(file);
                },
                error: (error) => {
                  console.log(error.message);
                },
              });
            } else {
              this.answerFileUrls[i][j] = "missing";
            }
          }
          for (let i = 0; i < module.questions[0].answers.length; i++) {
            this.incAnswerRandomIndexList[i] = i;
          }
          for (let i = this.incAnswerRandomIndexList.length - 1; i > 0; i--) {
            const randomIndex = Math.floor(Math.random() * (i + 1));
            [this.incAnswerRandomIndexList[i], this.incAnswerRandomIndexList[randomIndex]] = [this.incAnswerRandomIndexList[randomIndex], this.incAnswerRandomIndexList[i]];
          }
        }
        if (this.module?.mediaSynthesis) {
          this.dataService.getFile(this.module!.mediaSynthesis).subscribe({
            next: (file) => {
              this.synthesisFileUrl = URL.createObjectURL(file);
            },
            error: (error) => {
              console.log(error.message);
            },
          });
        }
      });
    }
  }

  setLanguagesSelected(): Language[] {
    const languageArray: Language[] = [];
    this.languageList?.forEach((language) => {
      if (this.module?.moduleTranslations.findIndex((l) => l.languageId == language.id) != -1) {
        languageArray.push(language);
      }
    });
    this.languagesSelected = languageArray;
    return languageArray;
  }

  displayModuleName(): string | undefined {
    const selectedTranslation = this.module?.moduleTranslations.find((t) => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.name;
    }
    return undefined;
  }

  displayQuestion(index: number): string | undefined {
    const question = this.module?.questions[index];
    const selectedTranslation = question?.questionTranslations.find((t) => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.question;
    }
    return undefined;
  }

  displayAnswer(answer: Answer): string | undefined {
    const selectedTranslation = answer?.answerTranslations.find((t) => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.answer;
    }
    return undefined;
  }

  displayExplanationInc(answer: Answer): string | undefined {
    const selectedTranslation = answer?.answerTranslations.find((t) => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.answerExplanation;
    }
    return undefined;
  }

  nextQuestionInc() {
    if (this.currentQuestion != this.highestQuestion) {
      this.currentQuestion++;
      this.selectedAnswerId = this.selectedAnswerList[this.currentQuestion - 1];
    }
  }

  previousQuestionInc() {
    if (this.currentQuestion != 1) {
      this.currentQuestion--;
      this.selectedAnswerId = this.selectedAnswerList[this.currentQuestion - 1];
    }
  }

  validateQuestionInc() {
    if (this.selectedAnswerId != undefined) {
      if (this.currentQuestion === this.highestQuestion) {
        this.selectedAnswerList.push(this.selectedAnswerId);
      } else {
        this.selectedAnswerList[this.currentQuestion - 1] = this.selectedAnswerId;
      }
      this.selectedAnswerId = undefined;
      if (this.currentQuestion < this.module!.questions[0].answers.length) {
        if (this.currentQuestion === this.highestQuestion) {
          this.highestQuestion++;
        } else {
          this.selectedAnswerId = this.selectedAnswerList[this.currentQuestion];
        }
        this.currentQuestion++;
      } else {
        this.resultsVisualisation = true;
      }
    }
  }

  isAnswerLocked(answerId: number | undefined): boolean {
    if (this.module?.type !== this.enumModuleType.QuizIncremental) {
      return false;
    }
    for (let i = 0; i < this.highestQuestion; i++) {
      if (this.selectedAnswerList[i] === answerId && i != this.currentQuestion - 1) {
        return true;
      }
    }
    return false;
  }

  displayAnswerFromId(id: number): string | undefined {
    const answer = this.module!.questions[0].answers.find((a) => a.id === id);
    const selectedTranslation = answer?.answerTranslations.find((t) => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.answer;
    }
    return undefined;
  }

  isRightAnswerInc(index: number): boolean {
    if (this.module?.questions[0].answers[index].id === this.selectedAnswerList[index]) {
      return true;
    } else {
      return false;
    }
  }

  selectAnswer(answerId: number) {
    if (this.selectedAnswerId === answerId || this.isAnswerLocked(answerId)) {
      this.selectedAnswerId = undefined;
    } else {
      this.selectedAnswerId = answerId;
    }
  }

  displaySynthesis(): string | undefined {
    const selectedTranslation = this.module?.moduleTranslations.find((t) => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.synthesis;
    }
    return undefined;
  }

  showExplanationPopupInc(index: number) {
    this.explanationPopupVisible = true;
    this.selectedQuestion = this.displayQuestion(0);
    this.selectedExplanation = this.displayExplanationInc(this.module!.questions[0].answers[index]);
    this.selectedFileUrl = this.answerFileUrls[0][index];
  }

  toggleSynthesisVisualisation() {
    if (this.synthesisVisualisation) {
      this.synthesisVisualisation = false;
    } else {
      this.synthesisVisualisation = true;
    }
  }

  backArrowClicked() {
    if (this.module?.pointOfInterestId) {
      this.entityIdChanged.emit(this.module?.pointOfInterestId);
    }
    this.entityTypeChanged.emit("pointOfInterest");
  }
}
