<div class="card">
  <div class="header">
    <h2 title="Cette section sert à configurer le contenu qui sera visible par l'utilisateur">Contenu de l'activité<i class="bi bi-question-circle infos-title-section margin-left-small"></i></h2>
    @for (language of languagesSelected; track language.id) {
      <app-language-state [language]="language" [languageState]="showLanguageState(language.id)"> </app-language-state>
    }
  </div>

  @if (languagesSelected.length === 0) {
    <p>Aucune langue n'est associée à l'activité.</p>
  } @else {
    <form [formGroup]="multiLanguageForm">
      <div class="horizontal-display">
        <div class="form-field">
          <label htmlFor="moduleName">Langue en cours de modification</label>
          <p class="field-explanation">Vous êtes en train de modifier les contenus de l'application dans la langue suivante:</p>
          <p-select [options]="languagesSelected" optionValue="id" optionLabel="name" formControlName="currentLanguage" (onChange)="setLanguage($event.value)" />
        </div>
        <div class="form-field">
          <span class="">
            <label htmlFor="moduleName">Nom de l'activité</label>
            <p class="field-explanation">Comment souhaitez-vous nommer votre activité</p>
            <input pInputText id="moduleName" formControlName="moduleName" (change)="saveModuleNameAndSynthesis()" [maxlength]="inputLength.maxModuleName" />
            <small class="char-count"> ({{ countNameCharacters() }}/{{ inputLength.maxModuleName }}) </small>
          </span>
        </div>
      </div>
    </form>

    @if (moduleType === enumModuleType.QuizTrueFalse || moduleType === enumModuleType.QuizMultipleChoice || moduleType === enumModuleType.QuizIncremental) {
      @if (moduleType !== enumModuleType.QuizIncremental) {
        <div class="horizontal-display">
          <h3>Questions</h3>
          <i tabIndex="0" aria-hidden="true" class="bi bi-plus-circle-dotted action-buttons-item" title="Ajouter une nouvelle question" (click)="addQuestion()"> </i>
        </div>

        @if (quizQuestions.length === 0) {
          <p>Aucune question n'est associée à cette activité.</p>
        }
      }

      <form [formGroup]="trueFalseQuizForm">
        <div formArrayName="items">
          @for (quizQuestion of quizQuestions; let indexQuestion = $index; track indexQuestion) {
            <div class="question-card">
              @if (moduleType !== enumModuleType.QuizIncremental) {
                <div class="horizontal-display grey-color">
                  <div class="header-question">
                    @if (showQuestion[indexQuestion]) {
                      <i tabIndex="0" aria-hidden="true" class="bi bi-chevron-down chevron" title="Replier question" (click)="showAndSetQuestion(false, indexQuestion)"> </i>
                    } @else {
                      <i tabIndex="0" aria-hidden="true" class="bi bi-chevron-right chevron" title="Déplier question" (click)="showAndSetQuestion(true, indexQuestion)"> </i>
                    }
                    <h4>Question {{ indexQuestion + 1 }}</h4>
                    @for (language of languagesSelected; track language) {
                      <app-language-state [isSmall]="true" [language]="language" [languageState]="showLanguageStateQuestion(language.id, indexQuestion)"> </app-language-state>
                    }

                    @if (quizQuestions[indexQuestion].correctAnswer !== enumCorrectAnswer.None) {
                      <i tabIndex="0" aria-hidden="true" class="bi bi-check-circle-fill ready-to-publish margin-left" title="Le choix de réponse est bien renseigné"> </i>
                    } @else {
                      <i tabIndex="0" aria-hidden="true" class="bi bi-x-circle-fill not-ready-to-publish margin-left" title="Le choix de réponse n'est pas renseigné"> </i>
                    }

                    @if ((quizQuestions[indexQuestion].media !== undefined && quizQuestions[indexQuestion].media !== null) || quizQuestions[indexQuestion].answersWithImages) {
                      @if (displayImageCompletionState(indexQuestion)) {
                        <i tabIndex="0" aria-hidden="true" class="bi bi-card-image ready-to-publish medium margin-left" title="La question contient des images"></i>
                      } @else {
                        <i tabIndex="0" aria-hidden="true" class="bi bi-card-image not-ready-to-publish medium margin-left" title="La question contient des images manquantes"> </i>
                      }
                    }
                  </div>
                  <i tabIndex="0" aria-hidden="true" class="bi bi-x-lg action-buttons-item" title="Supprimer la question" (click)="removeQuestion(indexQuestion)"> </i>
                </div>
              }

              @if (showQuestion[indexQuestion] || moduleType === enumModuleType.QuizIncremental) {
                <div class="question-content-card" [formGroupName]="indexQuestion">
                  <div class="horizontal-display margin-bottom">
                    <div class="question-field">
                      <span>
                        <label htmlFor="questionInput">Intitulé de la question</label>
                        <p class="field-explanation">Quelle question voulez-vous poser à l'utilisateur</p>
                        <textarea
                          id="questionInput"
                          rows="1"
                          pTextarea
                          formControlName="questionInput"
                          autoResize="true"
                          [maxlength]="inputLength.maxQuestionInput"
                          (change)="saveChange(indexQuestion)">
                        </textarea>
                        <small class="char-count"> ({{ countQuestionCharacters(indexQuestion) }}/{{ inputLength.maxQuestionInput }}) </small>
                      </span>
                    </div>

                    @if (moduleType !== enumModuleType.QuizIncremental) {
                      @if (quizQuestions[indexQuestion].media !== undefined && quizQuestions[indexQuestion].media !== null) {
                        <div class="image-association grey-color">
                          <i tabIndex="0" aria-hidden="true" class="bi bi-card-image image-buttons" title="Visualiser l'image" (click)="visualizeImageQuestion(indexQuestion)"> </i>
                          <p class="image-name truncate-two-lines">{{ quizQuestions[indexQuestion].media?.name }}</p>
                          <i tabIndex="0" aria-hidden="true" class="bi bi-x-lg image-buttons small remove-image" title="Désassocier l'image" (click)="removeImageQuestion(indexQuestion)"> </i>
                        </div>
                      } @else {
                        <div class="image-association grey-color">
                          <i tabIndex="0" aria-hidden="true" class="bi bi-card-image image-buttons" title="Associer une image" (click)="addImageQuestion(indexQuestion)"></i>
                          <p class="image-name">Aucune image associée</p>
                        </div>
                      }
                    }
                  </div>

                  @if (moduleType === enumModuleType.QuizTrueFalse) {
                    <label htmlFor="correctAnswer">Bonne réponse</label>
                    <p class="field-explanation">Choisissez quelle réponse sera la réponse valide pour l'utilisateur.</p>

                    <div class="radio-buttons">
                      <div class="flex align-items-center">
                        <input [id]="'true' + indexQuestion" type="radio" value="true" formControlName="correctAnswer" (change)="saveChange(indexQuestion)" />
                        <label [for]="'true' + indexQuestion" class="ml-2">Vrai</label>
                      </div>

                      <div class="flex align-items-center margin-left">
                        <input [id]="'false' + indexQuestion" type="radio" value="false" formControlName="correctAnswer" (change)="saveChange(indexQuestion)" />
                        <label [for]="'false' + indexQuestion" class="ml-3">Faux</label>
                      </div>
                    </div>
                  } @else {
                    <div class="horizontal-display">
                      <label for="idk">Réponses</label>
                      @if (moduleType !== enumModuleType.QuizIncremental) {
                        <div>
                          <p-checkbox [binary]="true" inputId="image-answer" title="" formControlName="answersWithImage" (onChange)="answerWithImage($event, indexQuestion)" />
                          <label for="image-answer" class="choice-image-answers">Réponse avec image</label>
                        </div>
                      }

                      <i tabIndex="0" aria-hidden="true" class="bi bi-plus-circle-dotted action-buttons-item" title="Ajouter une nouvelle réponse" (click)="addAnswer(indexQuestion)"> </i>
                    </div>
                    @for (answer of quizQuestion.answers; let indexAnswer = $index; track indexAnswer) {
                      <div class="answer-quiz">
                        @if (moduleType !== enumModuleType.QuizIncremental) {
                          <input
                            [id]="'false' + indexQuestion"
                            type="radio"
                            title="Choix de bonne réponse"
                            [value]="indexAnswer + 1"
                            formControlName="correctAnswer"
                            (change)="saveChoiceAnswer(indexQuestion, indexAnswer)" />
                        }

                        <div class="textarea" formArrayName="answers">
                          @if (moduleType === enumModuleType.QuizIncremental && indexAnswer === 0) {
                            <p class="margin-left">Intitulé de la réponse</p>
                          }

                          <div class="flex-display">
                            @if (moduleType === enumModuleType.QuizIncremental) {
                              <p class="index-answer">{{ indexAnswer + 1 }}</p>
                              <textarea
                                rows="1"
                                pTextarea
                                [formControlName]="indexAnswer"
                                autoResize="true"
                                [maxlength]="inputLength.maxAnswerIncrementalInput"
                                (change)="saveAnswer(indexQuestion, indexAnswer)">
                              </textarea>
                              <small class="char-count-inline"> ({{ countAnswerCharacters(indexQuestion, indexAnswer) }}/{{ inputLength.maxAnswerIncrementalInput }}) </small>
                            } @else {
                              <textarea rows="1" pTextarea [formControlName]="indexAnswer" autoResize="true" [maxlength]="inputLength.maxAnswerInput" (change)="saveAnswer(indexQuestion, indexAnswer)">
                              </textarea>
                              <small class="char-count-inline"> ({{ countAnswerCharacters(indexQuestion, indexAnswer) }}/{{ inputLength.maxAnswerInput }}) </small>
                            }
                          </div>
                        </div>

                        @if (moduleType === enumModuleType.QuizIncremental) {
                          <div class="textarea margin-left-small" formArrayName="answerExplanation">
                            @if (moduleType === enumModuleType.QuizIncremental && indexAnswer === 0) {
                              <p class="margin-left-small">Explication de la réponse (facultatif)</p>
                            }
                            <div class="horizontal-display">
                              <textarea rows="1" pTextarea [formControlName]="indexAnswer" (change)="saveAnswer(0, indexAnswer)" autoResize="true" [maxlength]="inputLength.maxAnswerExplanation">
                              </textarea>
                              <small class="char-count-inline">({{ countExplanationIncrementalCharacters(indexAnswer) }}/{{ inputLength.maxAnswerExplanation }})</small>
                            </div>
                          </div>
                        }

                        @if (quizQuestion.answersWithImages === true || moduleType === enumModuleType.QuizIncremental) {
                          @if (answer.media !== undefined && answer.media !== null) {
                            <div class="image-association grey-color">
                              <i tabIndex="0" aria-hidden="true" class="bi bi-card-image image-buttons" title="Visualiser l'image" (click)="visualizeImageAnswer(indexQuestion, indexAnswer)"> </i>
                              <p class="image-name truncate-two-lines" [title]="'Nom du média: ' + answer.media.name + answer.media.extension">{{ answer.media.name }}</p>
                              <i
                                tabIndex="0"
                                aria-hidden="true"
                                class="bi bi-x-lg image-buttons small remove-image"
                                title="Désassocier l'image"
                                (click)="removeImageAnswer(indexQuestion, indexAnswer)">
                              </i>
                            </div>
                          } @else {
                            <div class="image-association grey-color">
                              <i
                                tabIndex="0"
                                aria-hidden="true"
                                class="bi bi-card-image image-buttons not-ready-to-publish"
                                title="Associer une image"
                                (click)="addImageAnswer(indexQuestion, indexAnswer)"></i>
                              <p class="image-name">Aucune image associée</p>
                            </div>
                          }
                        }

                        @if (quizQuestion.answers && quizQuestion.answers.length > 2) {
                          <i tabIndex="0" aria-hidden="true" class="bi bi-x-lg action-buttons-item" title="Supprimer la réponse" (click)="removeAnswer(indexQuestion, indexAnswer)"> </i>
                        }
                      </div>
                      <!-- @if (moduleType === enumModuleType.QuizIncremental) {
                      <div class="textarea" formArrayName="answerExplanation">
                        <textarea rows="1" pTextarea [formControlName]="indexAnswer" (change)="saveAnswer(0, indexAnswer)" autoResize="true" maxlength="150"> </textarea>
                      </div>
                    } -->
                    }
                  }

                  @if (moduleType !== enumModuleType.QuizIncremental) {
                    <div class="margin-top">
                      <label for="idk">Explication de la réponse (facultatif)</label>
                      <textarea rows="1" pTextarea formControlName="answerExplanation" autoResize="true" [maxlength]="inputLength.maxAnswerExplanation" (change)="saveChange(indexQuestion)">
                      </textarea>
                      <p class="right-align">({{ countExplanationCharacters(indexQuestion) }}/{{ inputLength.maxAnswerExplanation }})</p>
                    </div>
                  }
                </div>
              }
            </div>
          }
        </div>
      </form>

      <h3>Synthèse du quiz (facultatif)</h3>

      <div class="horizontal-display">
        <div class="text-synthesis">
          <textarea rows="1" pTextarea [formControl]="textSynthesis" (change)="saveModuleNameAndSynthesis()" autoResize="true" [maxLength]="inputLength.maxQuizSynthesis"></textarea>
          <p class="char-count">({{ countSynthesisCharacters() }}/{{ inputLength.maxQuizSynthesis }})</p>
        </div>

        @if (imageSynthesis !== undefined) {
          <div class="image-association grey-color">
            <i tabIndex="0" aria-hidden="true" class="bi bi-card-image image-buttons" title="Visualiser l'image" (click)="visualizeImageSynthesis()"> </i>
            <p class="image-name truncate-two-lines" [title]="'Nom du média: ' + imageSynthesis.name + imageSynthesis.extension">{{ imageSynthesis.name }}</p>
            <i tabIndex="0" aria-hidden="true" class="bi bi-x-lg image-buttons small remove-image" title="Désassocier l'image" (click)="removeImageSynthesis()"> </i>
          </div>
        } @else {
          <div class="image-association grey-color">
            <i tabIndex="0" aria-hidden="true" class="bi bi-card-image image-buttons" title="Associer une image" (click)="addImageSynthesis()"></i>
            <p class="image-name">Aucune image associée</p>
          </div>
        }
      </div>
    }
  }
  @if (moduleType === enumModuleType.Gallery) {
    <app-module-gallery [multiLanguageForm]="multiLanguageForm" [mediaList]="mediaList" [languagesSelected]="languagesSelected"> </app-module-gallery>
  }
</div>

<app-media-association
  [visible]="visibleMediaPopup"
  [mediaList]="mediaList"
  [filteredMediaList]="mediaList"
  [mediaVisualisationFromPage]="mediaVisualisationFromPage"
  [mediaToDisplay]="selectedMedia"
  [fileUrl]="fileUrl"
  [fileBlob]="fileBlob"
  (closePopupEmitter)="closeMediaPopup()"
  (associatedMediaEmitter)="associateMedia($event)">
</app-media-association>
