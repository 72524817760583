import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { AuthService } from "app/Services/Auth-Service/auth.service";

/*
 * The aim of this guard is to redirect the user to the home page of the project is he is trying to access to the login page being already connected
 * There is three different results possible:
 * redirection to /projectName/home if the user is already connected and has a Client role
 * redirection to /projectName/administration if the user is already connected and has a Administrator role
 * redirection to /login if the user isn't connected
 * This guard seems to be the same as base route guard but the difference is in the returns, if we use base route guard on the login page, it will loop on it and freeze the application
 */

export const loginPageGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  if (authService.isLoggedIn()) {
    if (!authService.hasSelectedProject()) {
      router.navigate(["project-selection"], { state: { fromLoginPage: true } });
    } else if (authService.getUserType() === "Administrator") {
      router.navigate([`/${authService.getProjectName()}/administration`]);
    } else {
      router.navigate([`/${authService.getProjectName()}/home`]);
    }
    return false;
  } else {
    return true;
  }
};
