import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { ItineraryDetail } from "app/Models/itineraryDetail";
import { Language } from "app/Models/language";
import { PointOfInterest } from "app/Models/point-of-interest";
import { DataService } from "app/Services/Data-Service/data.service";

@Component({
  selector: "app-itinerary-previsualisation",
  templateUrl: "./itinerary-previsualisation.component.html",
  styleUrl: "./itinerary-previsualisation.component.css",
})
export class ItineraryPrevisualisationComponent implements OnChanges {
  @Input() selectedLanguageId?: number;
  @Input() entityId?: number;
  @Input() languageList?: Language[] = [];

  @Output() languagesSelectedChanged: EventEmitter<Language[]> = new EventEmitter<Language[]>();
  @Output() entityTypeChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() entityIdChanged: EventEmitter<number> = new EventEmitter<number>();

  itinerary?: ItineraryDetail;

  constructor(private dataService: DataService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["entityId"] && this.entityId) {
      this.dataService.getItinerary(this.entityId).subscribe((itinerary) => {
        this.itinerary = itinerary;
        this.languagesSelectedChanged.emit(this.setLanguagesSelected());
      });
    }
  }

  setLanguagesSelected(): Language[] {
    const languageArray: Language[] = [];
    this.languageList?.forEach((language) => {
      if (this.itinerary?.itineraryTranslations.findIndex((l) => l.languageId == language.id) != -1) {
        languageArray.push(language);
      }
    });
    return languageArray;
  }

  displayName(pointOfInterest: PointOfInterest): string | undefined {
    const selectedTranslation = pointOfInterest.pointOfInterestTranslations.find((t) => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.name;
    }
    return undefined;
  }

  displayDetails(pointOfInterest: PointOfInterest): string | undefined {
    const selectedTranslation = pointOfInterest.pointOfInterestTranslations.find((t) => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.details;
    }
    return undefined;
  }

  displayItineraryName(): string | undefined {
    const selectedTranslation = this.itinerary?.itineraryTranslations.find((t) => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.name;
    }
    return undefined;
  }

  backArrowClicked() {
    this.entityIdChanged.emit(0);
    this.entityTypeChanged.emit("");
  }

  selectPointOfInterest(pointOfInterest: PointOfInterest) {
    this.entityIdChanged.emit(pointOfInterest.id);
    this.entityTypeChanged.emit("pointOfInterest");
  }
}
