@if (itineraryList) {
  <div class="previsualisation-container">
    <div class="title-container">
      <span class="title">Parcours</span>
    </div>
    <div class="itinerary-list">
      @for (itinerary of itineraryList; track $index) {
        @if (hasSelectedLanguage(itinerary) && selectedLanguageId) {
          <div tabindex="0" aria-hidden="true" class="itinerary-item" (click)="selectItinerary(itinerary)" (keypress)="selectItinerary(itinerary)">
            <div class="itinerary-info">
              <div style="display: flex">
                <img class="poi-icon" src="assets/images/Point_Localisation_Grey.png" alt="image" />
                <span> {{ itinerary.countOfPointOfInterest }} étape(s)</span>
              </div>
              @if (!displayName(itinerary)) {
                <div class="itinerary-name placeholder">Nom du parcours</div>
              } @else {
                <div class="itinerary-name">{{ displayName(itinerary) }}</div>
              }
              @if (!displayDetails(itinerary)) {
                <div class="itinerary-details placeholder">Détails du parcours</div>
              } @else {
                <div class="itinerary-details">{{ displayDetails(itinerary) }}</div>
              }
            </div>
            <img style="height: 65px" src="assets/images/Arrow_Next_Grey.png" alt="image" />
          </div>
        }
      }
    </div>
    @if (isListEmpty()) {
      <p class="placeholder" style="text-align: center">Aucun parcours contenant la langue {{ displayLanguage() }} n’a été créé</p>
    }
  </div>
}
