@if (module) {
  <div class="previsualisation-container">
    @if (synthesisVisualisation) {
      <img tabindex="0" aria-hidden="true" src="assets/images/Arrow_Back.png" alt="image" class="arrow" (click)="toggleSynthesisVisualisation()" (keypress)="toggleSynthesisVisualisation()" />
    } @else {
      <img tabindex="0" aria-hidden="true" src="assets/images/Arrow_Back.png" alt="image" class="arrow" (click)="backArrowClicked()" (keypress)="backArrowClicked()" />
    }
    <div class="title-container">
      @if (displayModuleName()) {
        <span class="title">{{ displayModuleName() }}</span>
      } @else {
        <span class="title placeholder">Nom de l'activité</span>
      }
    </div>
    @if (!resultsVisualisation) {
      <i tabindex="0" aria-hidden="true" class="bi bi-question-circle question-circle" (click)="isInformationPopupVisible = true" (keypress)="isInformationPopupVisible = true"></i>
    }

    @if (!resultsVisualisation) {
      @if (module.questions.length) {
        @if (!displayQuestion(currentQuestion - 1)) {
          <p class="placeholder question">Intitulé de la question</p>
        } @else {
          <p class="question" [ngClass]="{ 'question-small-text': displayQuestion(0) && displayQuestion(0)!.length >= 170 }">{{ displayQuestion(currentQuestion - 1) }}</p>
        }

        <div class="horizontal-display-spaced answers-container">
          @if (questionFileUrls[currentQuestion - 1] !== "missing") {
            @if (!questionFileUrls[currentQuestion - 1]) {
              <div style="margin-left: 8rem">
                <p-progressSpinner></p-progressSpinner>
              </div>
            } @else {
              <img class="question-image" [src]="questionFileUrls[currentQuestion - 1]" alt="image" />
            }
          }
          @if (module.type === enumModuleType.QuizMultipleChoice && module!.questions.length && module!.questions[currentQuestion - 1].answersWithImages) {
            <div class="answer-list-with-media" [ngClass]="{ 'answers-one-line': questionFileUrls[currentQuestion - 1] === 'missing' }">
              @for (answer of module!.questions[currentQuestion - 1].answers; track $index) {
                <div class="answer-with-media horizontal-display">
                  <div
                    tabindex="0"
                    aria-hidden="true"
                    class="answer-image-container centered"
                    [ngClass]="{ 'selected-answer-with-image': selectedAnswerId === answer.id }"
                    (click)="selectAnswer(answer.id!)"
                    (keypress)="selectAnswer(answer.id!)">
                    @if (answerFileUrls[currentQuestion - 1] && answerFileUrls[currentQuestion - 1][$index]) {
                      @if (answerFileUrls[currentQuestion - 1][$index] === "missing") {
                        <i class="bi bi-card-image placeholder" style="font-size: 3rem"></i>
                      } @else {
                        <img class="image-fill" [src]="answerFileUrls[currentQuestion - 1][$index]" alt="image" />
                      }
                    } @else {
                      <p-progressSpinner></p-progressSpinner>
                    }
                  </div>
                  <div class="answer-with-media-text">{{ displayAnswer(answer) }}</div>
                  @if (!displayAnswer(answer)) {
                    <div class="answer-with-media-text placeholder">Réponse</div>
                  }
                </div>
              }
            </div>
          } @else {
            <div class="answer-list">
              @if (module.type === enumModuleType.QuizTrueFalse) {
                <div tabindex="0" aria-hidden="true" class="answer" [ngClass]="{ 'selected-answer': selectedAnswerId === 1 }" (click)="selectAnswer(1)" (keypress)="selectAnswer(1)">Vrai</div>
                <div tabindex="0" aria-hidden="true" class="answer" [ngClass]="{ 'selected-answer': selectedAnswerId === 2 }" (click)="selectAnswer(2)" (keypress)="selectAnswer(2)">Faux</div>
              } @else if (module!.questions.length) {
                @for (answer of module!.questions[currentQuestion - 1].answers; track $index) {
                  <div
                    tabindex="0"
                    aria-hidden="true"
                    class="answer"
                    [ngClass]="{ 'selected-answer': selectedAnswerId === answer.id, 'small-text': displayAnswer(answer) && displayAnswer(answer)!.length >= 50 }"
                    (click)="selectAnswer(answer.id!)"
                    (keypress)="selectAnswer(answer.id!)">
                    {{ displayAnswer(answer) }}
                    @if (!displayAnswer(answer)) {
                      <span class="placeholder">Réponse</span>
                    }
                  </div>
                }
              }
            </div>
          }
        </div>

        <span class="question-count">{{ currentQuestion + "/" + module.questions.length }}</span>

        <button class="validation-button" [ngClass]="{ 'button-disabled': selectedAnswerId === undefined }" (click)="validateQuestion()">Valider</button>
      } @else {
        @if (languagesSelected && languagesSelected.length > 0) {
          <p class="placeholder">Aucune question sauvegardée</p>
        } @else {
          <p class="placeholder">Aucune langue définie pour cette activité</p>
        }
      }
    } @else {
      @if (!synthesisVisualisation) {
        <div class="result-list scrollbar">
          @for (question of module.questions; track $index) {
            <div class="result-container" [ngClass]="{ 'wrong-answer': !isRightAnswer($index) }">
              <div class="question-result">
                <span class="bold">{{ displayQuestion($index) }}</span>
                @if (!displayQuestion($index)) {
                  <span class="placeholder">Intitulé de la question</span>
                }
                <div>
                  <span class="bold">Votre réponse : </span>
                  <span>{{ displayAnswerFromIndex($index) }}</span>
                  @if (!displayAnswerFromIndex($index)) {
                    <span class="placeholder">Réponse</span>
                  }
                </div>
                <span class="bold"
                  >La bonne réponse «{{ displayGoodAnswer($index) }}
                  @if (!displayGoodAnswer($index)) {
                    <span class="placeholder">Réponse </span>
                  }
                  @if (displayGoodAnswer($index) === " ") {
                    <span class="placeholder">Aucune bonne réponse sélectionnée </span>
                  }
                  »
                </span>
              </div>
              <div class="horizontal-display">
                @if (questionFileUrls[$index] !== "missing") {
                  @if (questionFileUrls[$index]) {
                    <div class="result-image-container centered">
                      <span class="image-label">Question</span>
                      <img class="image-fill" [src]="questionFileUrls[$index]" alt="image" />
                    </div>
                  } @else {
                    <div class="result-image-container centered">
                      <span class="image-label">Question</span>
                      <p-progressSpinner></p-progressSpinner>
                    </div>
                  }
                }
                @if (module.type === enumModuleType.QuizMultipleChoice && answerFileUrls[$index][getGoodAnswerIndex(question) - 1] !== "missing" && question.answersWithImages) {
                  @if (answerFileUrls[$index][getGoodAnswerIndex(question) - 1]) {
                    <div class="result-image-container centered">
                      <span class="image-label">Réponse</span>
                      <img class="image-fill" [src]="answerFileUrls[$index][getGoodAnswerIndex(question) - 1]" alt="image" />
                    </div>
                  } @else {
                    <div class="result-image-container centered">
                      <span class="image-label">Réponse</span>
                      <p-progressSpinner></p-progressSpinner>
                    </div>
                  }
                }
              </div>
              @if (question.questionTranslations[0].answerExplanation !== "") {
                <img
                  tabindex="0"
                  aria-hidden="true"
                  src="assets/images/i_Icone_1X.png"
                  alt="image"
                  class="explanation-icon"
                  (click)="showExplanationPopup($index)"
                  (keypress)="showExplanationPopup($index)" />
              }
            </div>
          }
        </div>
        <div class="score centered">
          {{ displayScore() }}
          <span>Résultat personnel</span>
        </div>
        @if (synthesisFileUrl !== "" || module!.moduleTranslations[0].synthesis !== "") {
          <img
            tabindex="0"
            aria-hidden="true"
            src="assets/images/Synthese_Button.png"
            alt="image"
            class="synthesis-icon"
            (click)="toggleSynthesisVisualisation()"
            (keypress)="toggleSynthesisVisualisation()" />
        }
      } @else {
        <div class="synthesis-container vertical-display scrollbar">
          <p style="text-align: center">{{ displaySynthesis() }}</p>
          @if (module.mediaSynthesis) {
            @if (synthesisFileUrl !== "") {
              <img style="max-height: 100%; max-width: 100%" [src]="synthesisFileUrl" alt="image" />
            } @else {
              <p-progressSpinner></p-progressSpinner>
            }
          }
        </div>
      }
    }

    <app-information-popup [visible]="isInformationPopupVisible" [type]="module.type" (closePopupEmitter)="isInformationPopupVisible = false"> </app-information-popup>

    <app-explanation-popup
      [question]="selectedQuestion"
      [explanation]="selectedExplanation"
      [imageFileUrl]="selectedFileUrl"
      [visible]="explanationPopupVisible"
      (closePopupEmitter)="explanationPopupVisible = false">
    </app-explanation-popup>
  </div>
}
