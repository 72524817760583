import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { moduleType } from "app/Enumerations/module-type.enum";
import { Language } from "app/Models/language";
import { Answer, Module, Question } from "app/Models/module";
import { DataService } from "app/Services/Data-Service/data.service";
import { NgClass } from "@angular/common";
import { ProgressSpinner } from "primeng/progressspinner";
import { InformationPopupComponent } from "../../../../Popups/previsualisation/information-popup/information-popup.component";
import { ExplanationPopupComponent } from "../../../../Popups/previsualisation/explanation-popup/explanation-popup.component";

@Component({
  selector: "app-quiz-previsualisation",
  templateUrl: "./quiz-previsualisation.component.html",
  styleUrl: "./quiz-previsualisation.component.css",
  imports: [NgClass, ProgressSpinner, InformationPopupComponent, ExplanationPopupComponent],
})
export class QuizPrevisualisationComponent implements OnChanges {
  @Input() selectedLanguageId?: number;
  @Input() entityId?: number;
  @Input() languageList?: Language[] = [];

  @Output() languagesSelectedChanged: EventEmitter<Language[]> = new EventEmitter<Language[]>();
  @Output() entityTypeChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() entityIdChanged: EventEmitter<number> = new EventEmitter<number>();

  module?: Module;
  languagesSelected?: Language[] = [];
  currentQuestion: number = 1;
  selectedAnswerId?: number;
  selectedAnswerList: number[] = [];
  resultsVisualisation: boolean = false;
  synthesisVisualisation: boolean = false;
  isInformationPopupVisible: boolean = false;

  questionFileUrls: string[] = [];
  answerFileUrls: string[][] = [];
  synthesisFileUrl: string = "";

  explanationPopupVisible: boolean = false;
  selectedQuestion?: string;
  selectedExplanation?: string;
  selectedFileUrl?: string;

  enumModuleType = moduleType;

  constructor(private dataService: DataService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["entityId"] && this.entityId) {
      this.dataService.getModule(this.entityId.toString()).subscribe((module) => {
        this.module = module;
        this.languagesSelectedChanged.emit(this.setLanguagesSelected());
        this.isInformationPopupVisible = true;
        for (let i = 0; i < this.module!.questions.length; i++) {
          if (this.module && this.module.questions[i] && this.module.questions[i].media) {
            this.dataService.getFile(this.module.questions[i].media!).subscribe({
              next: (file) => {
                this.questionFileUrls[i] = URL.createObjectURL(file);
              },
              error: (error) => {
                console.log(error.message);
              },
            });
          } else {
            this.questionFileUrls[i] = "missing";
          }
          if (this.module?.type === this.enumModuleType.QuizMultipleChoice) {
            if (!this.answerFileUrls[i]) {
              this.answerFileUrls[i] = [];
            }
            for (let j = 0; j < this.module!.questions[i].answers.length; j++) {
              if (this.module!.questions[i].answers[j].media) {
                this.dataService.getFile(this.module!.questions[i].answers[j].media!).subscribe({
                  next: (file) => {
                    this.answerFileUrls[i][j] = URL.createObjectURL(file);
                  },
                  error: (error) => {
                    console.log(error.message);
                  },
                });
              } else {
                this.answerFileUrls[i][j] = "missing";
              }
            }
          }
        }
        if (this.module?.mediaSynthesis) {
          this.dataService.getFile(this.module!.mediaSynthesis).subscribe({
            next: (file) => {
              this.synthesisFileUrl = URL.createObjectURL(file);
            },
            error: (error) => {
              console.log(error.message);
            },
          });
        }
      });
    }
  }

  setLanguagesSelected(): Language[] {
    const languageArray: Language[] = [];
    this.languageList?.forEach((language) => {
      if (this.module?.moduleTranslations.findIndex((l) => l.languageId == language.id) != -1) {
        languageArray.push(language);
      }
    });
    this.languagesSelected = languageArray;
    return languageArray;
  }

  displayModuleName(): string | undefined {
    const selectedTranslation = this.module?.moduleTranslations.find((t) => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.name;
    }
    return undefined;
  }

  displayQuestion(index: number): string | undefined {
    const question = this.module?.questions[index];
    const selectedTranslation = question?.questionTranslations.find((t) => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.question;
    }
    return undefined;
  }

  displayExplanation(question: Question): string | undefined {
    const selectedTranslation = question?.questionTranslations.find((t) => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.answerExplanation;
    }
    return undefined;
  }

  showExplanationPopup(index: number) {
    this.explanationPopupVisible = true;
    if (this.module?.type === this.enumModuleType.QuizIncremental) {
      this.selectedQuestion = this.displayQuestion(0);
    } else {
      this.selectedQuestion = this.displayQuestion(index);
    }
    this.selectedExplanation = this.displayExplanation(this.module!.questions[index]);
    if (this.module?.type === this.enumModuleType.QuizMultipleChoice && this.module!.questions[index].answers[this.getGoodAnswerIndex(this.module!.questions[index]) - 1].media) {
      this.selectedFileUrl = this.answerFileUrls[index][this.getGoodAnswerIndex(this.module!.questions[index]) - 1];
    } else {
      this.selectedFileUrl = this.questionFileUrls[index];
    }
  }

  displaySynthesis(): string | undefined {
    const selectedTranslation = this.module?.moduleTranslations.find((t) => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.synthesis;
    }
    return undefined;
  }

  displayAnswer(answer: Answer): string | undefined {
    const selectedTranslation = answer?.answerTranslations.find((t) => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.answer;
    }
    return undefined;
  }

  displayAnswerFromIndex(index: number): string | undefined {
    if (this.module?.type === this.enumModuleType.QuizTrueFalse) {
      if (this.selectedAnswerList[index] === 1) {
        return "Vrai";
      } else {
        return "Faux";
      }
    }
    const answer = this.module!.questions[index].answers.find((a) => a.id === this.selectedAnswerList[index]);
    const selectedTranslation = answer?.answerTranslations.find((t) => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.answer;
    }
    return undefined;
  }

  displayAnswerFromId(id: number): string | undefined {
    const answer = this.module!.questions[0].answers.find((a) => a.id === id);
    const selectedTranslation = answer?.answerTranslations.find((t) => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.answer;
    }
    return undefined;
  }

  selectAnswer(answerId: number) {
    if (this.selectedAnswerId === answerId) {
      this.selectedAnswerId = undefined;
    } else {
      this.selectedAnswerId = answerId;
    }
  }

  validateQuestion() {
    if (this.selectedAnswerId != undefined) {
      this.selectedAnswerList.push(this.selectedAnswerId);
      this.selectedAnswerId = undefined;
      if (this.currentQuestion < this.module!.questions.length) {
        this.currentQuestion++;
      } else {
        this.resultsVisualisation = true;
      }
    }
  }

  displayScore(): string | undefined {
    let score = 0;
    for (let i = 0; i < this.module!.questions.length; i++) {
      if (this.isRightAnswer(i)) {
        score++;
      }
    }
    return score.toString() + "/" + this.module?.questions.length.toString();
  }

  isRightAnswer(index: number): boolean {
    if (this.module?.type === this.enumModuleType.QuizTrueFalse) {
      if (this.module?.questions[index].correctAnswer === this.selectedAnswerList[index]) {
        return true;
      }
    } else {
      const correctAnswer = this.getGoodAnswerIndex(this.module!.questions[index]);
      if (correctAnswer != -1 && this.selectedAnswerList[index] === this.module?.questions[index].answers[correctAnswer - 1].id) {
        return true;
      }
    }
    return false;
  }

  displayGoodAnswer(index: number): string | undefined {
    if (this.module?.type === this.enumModuleType.QuizTrueFalse) {
      switch (this.module?.questions[index].correctAnswer) {
        case 1:
          return "Vrai";

        case 2:
          return "Faux";

        default:
          return " ";
      }
    }
    const correctAnswer: number = this.getGoodAnswerIndex(this.module!.questions[index]);
    const answer = this.module?.questions[index].answers[correctAnswer - 1];
    const selectedTranslation = answer?.answerTranslations.find((t) => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.answer;
    }
    return undefined;
  }

  getGoodAnswerIndex(question: Question): number {
    switch (question.correctAnswer) {
      case 1:
        return 1;
      case 2:
        return 2;
      case 4:
        return 3;
      case 8:
        return 4;
    }
    return -1;
  }

  toggleSynthesisVisualisation() {
    if (this.synthesisVisualisation) {
      this.synthesisVisualisation = false;
    } else {
      this.synthesisVisualisation = true;
    }
  }

  backArrowClicked() {
    if (this.module?.pointOfInterestId) {
      this.entityIdChanged.emit(this.module?.pointOfInterestId);
    }
    this.entityTypeChanged.emit("pointOfInterest");
  }
}
