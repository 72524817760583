import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Dialog } from "primeng/dialog";

@Component({
  selector: "app-explanation-popup",
  templateUrl: "./explanation-popup.component.html",
  styleUrl: "./explanation-popup.component.css",
  imports: [Dialog],
})
export class ExplanationPopupComponent {
  @Input() visible: boolean = false;
  @Input() question?: string;
  @Input() explanation?: string;
  @Input() imageFileUrl?: string;

  @Output() closePopupEmitter: EventEmitter<void> = new EventEmitter<void>();

  closePopup() {
    this.closePopupEmitter.emit();
  }
}
