import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ModuleCustomTemplate } from "app/Models/module";
import { ModuleService } from "app/Services/Module-Service/module.service";
import { SelectModule } from "primeng/select";

@Component({
  selector: "app-module-custom",
  templateUrl: "./module-custom.component.html",
  styleUrl: "./module-custom.component.css",
  imports: [FormsModule, ReactiveFormsModule, SelectModule],
})
export class ModuleCustomComponent implements OnInit {
  @Input() moduleForm: FormGroup;
  @Input() moduleCustomTemplates: ModuleCustomTemplate[] = [];

  constructor(
    private moduleService: ModuleService,
    private fb: FormBuilder,
  ) {
    this.moduleForm = this.fb.group({
      moduleCustomTemplateId: [""],
    });
  }

  ngOnInit() {
    this.moduleForm.get("moduleCustomTemplateId")?.setValue(this.moduleService.getModuleCustomTemplateId());
  }

  selectModuleTemplate(id: number) {
    this.moduleService.setModuleCustomTemplateId(id);
  }
}
