import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { UserLogin } from "../../Models/userLogin";
import { environment } from "environments/environment";
import { JwtBearer } from "app/Models/jwtBearer";
import { Router } from "@angular/router";
import { MessageService } from "primeng/api";
import { ApiResponse } from "app/Models/apiResponse";
import { responseCode } from "app/Enumerations/response-code.enum";
import { jwtDecode } from "jwt-decode";
import { JwtBearerTest } from "app/Models/jwtBearerTest";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  projectName!: string;
  private isLogging = new BehaviorSubject<boolean>(false);
  userIsLogging = this.isLogging.asObservable();

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private router: Router,
  ) {}

  login(userLogin: UserLogin) {
    return this.http.post<ApiResponse<JwtBearer>>(`${environment.apiUrl}/api/Auth/Login/`, userLogin).subscribe({
      next: (data) => {
        if (data.errorCode == responseCode.GoToProjectSelection) {
          this.setSession(data.data, true);
          this.router.navigate([`/project-selection`], { state: { fromLoginPage: true } });
          this.messageService.add({ severity: "success", summary: "Connexion réussie", detail: "Bienvenue " + userLogin.email });
          this.isLogging.next(true);
        } else if (data.errorCode == responseCode.SuccessfulLogin) {
          this.setSession(data.data, false);
          if (this.getUserType() === "Administrator") {
            this.router.navigate([`/${this.getProjectName()}/administration`]);
          } else {
            this.router.navigate([`/${this.getProjectName()}/home`]);
          }
          this.messageService.add({ severity: "success", summary: "Connexion réussie", detail: "Bienvenue " + userLogin.email });
          this.isLogging.next(true);
        } else if (data.errorCode == responseCode.WrongPassword) {
          this.messageService.add({ severity: "error", summary: "Erreur de connexion", detail: data.errorMessage });
          this.isLogging.next(false);
        }
      },
      error: (err) => {
        this.handleError(err);
        if (err.error.errorCode == responseCode.WrongPassword) {
          this.messageService.add({ severity: "error", summary: "Erreur de connexion", detail: err.error.errorMessage });
        }
        this.isLogging.next(false);
      },
    });
  }

  selectProject(projectId: number) {
    return this.http.post<ApiResponse<JwtBearer>>(`${environment.apiUrl}/api/Auth/SelectProject/${projectId}`, null).subscribe({
      next: (data) => {
        if (data.errorCode == responseCode.SuccessfulLogin) {
          this.setSession(data.data, false);
          if (this.getUserType() === "Administrator") {
            this.router.navigate([`/${this.getProjectName()}/administration`]);
          } else {
            this.router.navigate([`/${this.getProjectName()}/home`]);
          }
          this.messageService.add({ severity: "success", summary: "Connexion réussie", detail: "" });
          this.isLogging.next(true);
        } else if (data.errorCode == responseCode.WrongPassword) {
          this.messageService.add({ severity: "error", summary: "Erreur de connexion", detail: data.errorMessage });
          this.isLogging.next(false);
        }
      },
      error: (err) => {
        this.handleError(err);
        if (err.error.errorCode == responseCode.WrongPassword) {
          this.messageService.add({ severity: "error", summary: "Erreur de connexion", detail: err.error.errorMessage });
        }
        this.isLogging.next(false);
      },
    });
  }

  logout() {
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("projects");
    localStorage.removeItem("projectName");
    localStorage.removeItem("first_name");
    localStorage.removeItem("surname");
    localStorage.removeItem("emailAddress");
  }

  private setSession(authResult: JwtBearer, firstConnection: boolean) {
    localStorage.setItem("first_name", authResult.firstName);
    localStorage.setItem("surname", authResult.surname);
    localStorage.setItem("emailAddress", authResult.emailAddress);
    localStorage.setItem("id_token", authResult.jwtBearer);
    localStorage.setItem("expires_at", authResult.expiresAt.toString());
    if (firstConnection) {
      localStorage.setItem("projects", JSON.stringify(authResult.projects));
    } else {
      localStorage.setItem("projectName", authResult.projectName);
    }
  }

  public isLoggedIn() {
    return this.getExpiration().getTime() > new Date().getTime();
    // if (this.getExpiration().getTime() > new Date().getTime() && localStorage.getItem("projectName") != null)
    //TODO: Replace Date() objet: https://momentjs.com/docs/#/-project-status/
  }

  public hasSelectedProject() {
    return this.getProjectName() !== "";
  }

  setUserIsLogging(value: boolean) {
    this.isLogging.next(value);
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getExpiration(): Date {
    const expiresAt = localStorage.getItem("expires_at");
    return new Date(expiresAt || ""); //TODO: Replace Date() objet
  }

  getProjectName(): string {
    return localStorage.getItem("projectName") || "";
  }

  getToken(): string | null {
    return localStorage.getItem("id_token");
  }

  getUserType(): string | null {
    const token = this.getToken();
    if (token) {
      const decodedToken = jwtDecode<JwtBearerTest>(token);
      return decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] || null;
    }
    return null;
  }

  getProjectUserCount(): number {
    const projects = localStorage.getItem("projects");
    if (projects != null) {
      return JSON.parse(projects).length;
    } else {
      return 0;
    }
  }

  getUserInitials(): string {
    const firstName = localStorage.getItem("first_name") || "";
    const surname = localStorage.getItem("surname") || "";
    return firstName.charAt(0) + surname.charAt(0);
  }

  getUserFullName(): string {
    const firstName = localStorage.getItem("first_name") || "";
    const surname = localStorage.getItem("surname") || "";
    return firstName + " " + surname;
  }

  getUserEmail(): string {
    const emailAddress = localStorage.getItem("emailAddress") || "";
    return emailAddress;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(`Backend returned code ${error.status}, body was: `, error.error);

      if (error.status == 400) {
        this.messageService.add({
          severity: "error",
          summary: "Erreur de connexion",
          detail: "Aucun utilisateur ne correspond à ces données de connexion. Veuillez vérifier vos informations de connexion",
        });
      }
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error("Something bad happened; please try again later"));
  }
}
