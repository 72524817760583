<p-dialog [(visible)]="visible" class="information-popup" [resizable]="false" [draggable]="false" [modal]="true" [closable]="false">
  <ng-template pTemplate="header">
    <div>
      <h2>{{ displayType() }}</h2>
    </div>
  </ng-template>

  <div class="vertical-display">
    @switch (type) {
      @case (enumModuleType.QuizMultipleChoice) {
        <img class="instruction-icon" src="assets/images/Consigne_Quiz.png" alt="image" />
      }
      @case (enumModuleType.QuizTrueFalse) {
        <img class="instruction-icon" src="assets/images/Consigne_Quiz_Vrai-Faux.png" alt="image" />
      }
      @case (enumModuleType.QuizIncremental) {
        <img class="instruction-icon" src="assets/images/Icone_Quiz_Incremental.png" alt="image" />
      }
    }
    <p style="text-align: center">{{ displayInstruction() }}</p>
    <button class="validation-button" (click)="closePopup()">J'ai compris</button>
  </div>
</p-dialog>
