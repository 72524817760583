import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Module } from "app/Models/module";
import { moduleTypeToString, moduleType } from "app/Enumerations/module-type.enum";
import { getPublishStateValue, publishState } from "app/Enumerations/publish-state.enum";
import { AuthService } from "app/Services/Auth-Service/auth.service";
import { entityType } from "app/Enumerations/entity-type.enum";
import { RouterLink } from "@angular/router";
import { PublicationStateComponent } from "../../../ReusableComponents/publication-state/publication-state.component";
import { CompletionStateComponent } from "../../../ReusableComponents/completion-state/completion-state.component";

@Component({
  selector: "app-added-module-card",
  templateUrl: "./added-module-card.component.html",
  styleUrls: ["./added-module-card.component.css"],
  imports: [RouterLink, PublicationStateComponent, CompletionStateComponent],
})
export class AddedModuleCardComponent {
  //Enumerations
  enumPublishState = publishState;
  enumModuleType = moduleType;
  enumEntityType = entityType;

  @Input() module!: Module;

  @Output() removeModuleEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output() dragStartedEmitter: EventEmitter<number[]> = new EventEmitter<number[]>();

  constructor(private authService: AuthService) {}

  displayModuleType() {
    return moduleTypeToString(this.module.type);
  }

  displayModuleState() {
    return getPublishStateValue(this.module.state);
  }

  removeModule() {
    this.removeModuleEmitter.emit(this.module.id);
  }

  dragStarted(event: MouseEvent) {
    event.preventDefault();
    this.dragStartedEmitter.emit([event.clientY, event.offsetY, this.module.order - 1]);
  }

  getProjectName(): string {
    return this.authService.getProjectName();
  }
}
