import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ModuleCustomAssociation, ModuleCustomTemplate } from "app/Models/module";
import { Project } from "app/Models/project";
import { Dialog } from "primeng/dialog";
import { PrimeTemplate } from "primeng/api";

@Component({
  selector: "app-module-custom-popup",
  templateUrl: "./module-custom-popup.component.html",
  styleUrl: "./module-custom-popup.component.css",
  imports: [Dialog, PrimeTemplate],
})
export class ModuleCustomPopupComponent {
  @Input() visible: boolean = false;
  @Input() moduleCustomAssociations: ModuleCustomAssociation[] = [];
  @Input() projects: Project[] = [];
  @Input() modules: ModuleCustomTemplate[] = [];

  addAssociation: boolean = false;
  removeAssociation: boolean = false;

  @Output() confirmActionEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  displayModuleAssociation(association: ModuleCustomAssociation) {
    if (association.isAssociated && !this.addAssociation) {
      this.addAssociation = true;
    } else if (!association.isAssociated && !this.removeAssociation) {
      this.removeAssociation = true;
    }
    const moduleName = this.modules.find((o) => o.id === association.moduleId)?.name;
    const projectName = this.projects.find((o) => o.id === association.projectId)?.identificationName;
    return moduleName + " - " + projectName;
  }

  confirmAction() {
    this.confirmActionEmitter.emit(true);
  }

  closePopup() {
    this.confirmActionEmitter.emit(false);
    this.addAssociation = false;
    this.removeAssociation = false;
  }
}
