import { CanActivateFn } from "@angular/router";
import { AuthService } from "app/Services/Auth-Service/auth.service";
import { inject } from "@angular/core";
import { Router } from "@angular/router";

/*
 * The aim of this guard is to prevent navigation for non logged users and to prevent that non authorized users (Clients role) access to the administration interface with the url
 */

export const authGuard: CanActivateFn = (route) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  if (authService.isLoggedIn()) {
    if (!authService.hasSelectedProject() && route.routeConfig?.path !== "project-selection") {
      // console.log('yo connecté et pas de proj select', route.routeConfig?.path);

      router.navigate(["project-selection"], { state: { fromLoginPage: true } });
    }

    if (authService.getUserType() === "Client" && route.routeConfig?.path == ":projectName/administration") {
      router.navigateByUrl(`/${authService.getProjectName()}/home`);
      return false;
    }
    return true;
  } else {
    router.navigateByUrl("/login");
    return false;
  }
};
