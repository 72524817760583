import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { FormControl } from "@angular/forms";
import { confirmDialogType } from "app/Enumerations/confirm-dialog-type.enum";
import { entityType } from "app/Enumerations/entity-type.enum";
import { Module } from "app/Models/module";
import { AuthService } from "app/Services/Auth-Service/auth.service";
import { DataService } from "app/Services/Data-Service/data.service";
import { Dialog } from "primeng/dialog";
import { MessageService, PrimeTemplate } from "primeng/api";
import { RouterLink } from "@angular/router";
import { ProgressSpinner } from "primeng/progressspinner";
import { ReactiveFormsModule } from "@angular/forms";
import { InputText } from "primeng/inputtext";

@Component({
  selector: "app-confirmation-popup",
  templateUrl: "./confirmation-popup.component.html",
  styleUrls: ["./confirmation-popup.component.css"],
  imports: [Dialog, PrimeTemplate, RouterLink, ProgressSpinner, ReactiveFormsModule, InputText],
})
export class ConfirmationPopupComponent implements OnChanges {
  @Input() visible: boolean = false;
  @Input() confirmTypeAction!: number;
  @Input() entityTypeAction!: number;
  @Input() multipleSelection!: boolean;
  @Input() entityId?: number;
  @Input() confirmationLabel?: string = "";

  // Media deletion
  modulesAssociatedToMedia: Module[] = [];
  modulesAssociatedAreLoading: boolean = true;

  @Output() confirmActionEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  enumDialogType = confirmDialogType;
  enumEntityType = entityType;

  inputConfirmation: FormControl = new FormControl("");

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private messageService: MessageService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["entityId"] && this.entityId) {
      this.dataService.getEntitiesAssociatedToMedia(this.entityId).subscribe({
        next: (modules) => {
          this.modulesAssociatedToMedia = modules;
          this.modulesAssociatedAreLoading = false;
        },
        error: (error) => {
          console.log(error.message);
        },
      });
    }
  }

  closePopup() {
    this.modulesAssociatedAreLoading = true;
    this.inputConfirmation.setValue("");
    this.confirmActionEmitter.emit(false);
  }

  confirmAction() {
    if (
      (this.confirmTypeAction === this.enumDialogType.Delete && this.entityTypeAction === this.enumEntityType.Itinerary && this.inputConfirmation.value !== this.confirmationLabel) ||
      (this.confirmTypeAction === this.enumDialogType.Delete && this.entityTypeAction === this.enumEntityType.User && this.inputConfirmation.value !== this.confirmationLabel)
    ) {
      this.messageService.add({ severity: "error", summary: "Suppression impossible", detail: 'Veuillez écrire "' + this.confirmationLabel + '" dans le champs prévu pour confirmer la suppression' });
    } else {
      this.confirmActionEmitter.emit(true);
    }
  }

  getProjectName(): string {
    return this.authService.getProjectName();
  }
}
