import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { fileType } from "app/Enumerations/file-type.enum";
import { moduleType } from "app/Enumerations/module-type.enum";
import { Language } from "app/Models/language";
import { MediaAssociation, Module } from "app/Models/module";
import { DataService } from "app/Services/Data-Service/data.service";
import { NgClass } from "@angular/common";
import { ProgressSpinner } from "primeng/progressspinner";

@Component({
  selector: "app-gallery-previsualisation",
  templateUrl: "./gallery-previsualisation.component.html",
  styleUrl: "./gallery-previsualisation.component.css",
  imports: [NgClass, ProgressSpinner],
})
export class GalleryPrevisualisationComponent implements OnChanges {
  @Input() selectedLanguageId?: number;
  @Input() entityId?: number;
  @Input() languageList?: Language[] = [];

  @Output() languagesSelectedChanged: EventEmitter<Language[]> = new EventEmitter<Language[]>();
  @Output() entityTypeChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() entityIdChanged: EventEmitter<number> = new EventEmitter<number>();

  module?: Module;
  languagesSelected?: Language[] = [];
  mediaVisualisation: boolean = false;

  galleryFileUrls: string[] = [];
  selectedFileIndex: number = -1;

  dimensions: { [key: string]: string } = {};

  enumModuleType = moduleType;
  enumFileType = fileType;

  constructor(private dataService: DataService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["entityId"] && this.entityId) {
      this.dataService.getModule(this.entityId.toString()).subscribe((module) => {
        this.module = module;
        this.languagesSelectedChanged.emit(this.setLanguagesSelected());
        for (let i = 0; i < this.module!.mediaAssociations.length; i++) {
          if (this.module && this.module.mediaAssociations[i]) {
            if (this.module.mediaAssociations[i].media.fileType === fileType.Image || this.module.mediaAssociations[i].media.fileType === fileType.Pdf) {
              this.dataService.getFile(this.module.mediaAssociations[i].media!).subscribe({
                next: (file) => {
                  this.galleryFileUrls[i] = URL.createObjectURL(file);
                },
                error: (error) => {
                  console.log(error.message);
                },
              });
            } else {
              this.galleryFileUrls[i] = this.dataService.streamFile(this.module.mediaAssociations[i].media);
            }
          } else {
            this.galleryFileUrls[i] = "";
          }
        }
      });
    }
  }

  displayModuleName(): string | undefined {
    const selectedTranslation = this.module?.moduleTranslations.find((t) => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.name;
    }
    return undefined;
  }

  setLanguagesSelected(): Language[] {
    const languageArray: Language[] = [];
    this.languageList?.forEach((language) => {
      if (this.module?.moduleTranslations.findIndex((l) => l.languageId == language.id) != -1) {
        languageArray.push(language);
      }
    });
    this.languagesSelected = languageArray;
    return languageArray;
  }

  displayMediaName(media: MediaAssociation): string | undefined {
    const selectedTranslation = media?.mediaAssociationTranslations.find((t) => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.name;
    }
    return media.media.name;
  }

  setSelectedMedia(index: number) {
    this.selectedFileIndex = index;
    this.mediaVisualisation = true;
  }

  downloadPdf() {
    const link = document.createElement("a");
    if (this.galleryFileUrls[this.selectedFileIndex] && this.module) {
      link.href = this.galleryFileUrls[this.selectedFileIndex];
      link.download = this.module.mediaAssociations[this.selectedFileIndex].media.name + this.module.mediaAssociations[this.selectedFileIndex].media.extension;
      link.click();
      link.remove();
    }
  }

  backArrowClicked() {
    if (this.module?.pointOfInterestId) {
      this.entityIdChanged.emit(this.module?.pointOfInterestId);
    }
    this.entityTypeChanged.emit("pointOfInterest");
  }
}
