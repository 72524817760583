@if (module) {
  <div class="previsualisation-container">
    @if (synthesisVisualisation) {
      <img tabindex="0" aria-hidden="true" src="assets/images/Arrow_Back.png" alt="image" class="arrow" (click)="toggleSynthesisVisualisation()" (keypress)="toggleSynthesisVisualisation()" />
    } @else {
      <img tabindex="0" aria-hidden="true" src="assets/images/Arrow_Back.png" alt="image" class="arrow" (click)="backArrowClicked()" (keypress)="backArrowClicked()" />
    }
    @if (!resultsVisualisation) {
      <i tabindex="0" aria-hidden="true" class="bi bi-question-circle question-circle" (click)="isInformationPopupVisible = true" (keypress)="isInformationPopupVisible = true"></i>
    }

    @if (!resultsVisualisation) {
      <div class="title-container">
        @if (displayModuleName()) {
          <span class="title">{{ displayModuleName() }}</span>
        } @else {
          <span class="title placeholder">Nom de l'activité</span>
        }
      </div>
      @if (languagesSelected?.length === 0) {
        <p class="placeholder">Aucune langue n'est définie pour cette activité</p>
      } @else {
        <i tabindex="0" aria-hidden="true" class="bi bi-question-circle question-circle" (click)="isInformationPopupVisible = true" (keypress)="isInformationPopupVisible = true"></i>
        @if (!displayQuestion(0)) {
          <p class="placeholder">Intitulé de la question</p>
        } @else {
          <p class="question" [ngClass]="{ 'question-small-text': displayQuestion(0) && displayQuestion(0)!.length >= 170 }">{{ displayQuestion(0) }}</p>
        }
        <div class="horizontal-display-spaced answers-container-inc">
          @if (answerFileUrls[0][currentQuestion - 1]) {
            @if (answerFileUrls[0][currentQuestion - 1] !== "missing") {
              <img class="question-image" [src]="answerFileUrls[0][currentQuestion - 1]" alt="image" />
            } @else {
              <span class="placeholder" style="margin-left: 5rem">Image manquante</span>
            }
          } @else {
            <div style="margin-left: 8rem">
              <p-progressSpinner></p-progressSpinner>
            </div>
          }
          <div
            class="answer-list-inc"
            [ngClass]="{ 'answer-list-medium-template': incAnswerRandomIndexList.length > 3 && incAnswerRandomIndexList.length < 7, 'answer-list-big-template': incAnswerRandomIndexList.length > 6 }">
            @for (answer of incAnswerRandomIndexList; track $index) {
              <div
                tabindex="0"
                aria-hidden="true"
                class="answer"
                [ngClass]="{
                  'selected-answer': selectedAnswerId === module.questions[0].answers[incAnswerRandomIndexList[$index]].id,
                  'locked-answer': isAnswerLocked(module.questions[0].answers[incAnswerRandomIndexList[$index]].id),
                  'small-text':
                    displayAnswer(module.questions[0].answers[incAnswerRandomIndexList[$index]]) && displayAnswer(module.questions[0].answers[incAnswerRandomIndexList[$index]])!.length >= 50,
                }"
                (click)="selectAnswer(module.questions[0].answers[incAnswerRandomIndexList[$index]].id!)"
                (keypress)="selectAnswer(module.questions[0].answers[incAnswerRandomIndexList[$index]].id!)">
                {{ displayAnswer(module.questions[0].answers[incAnswerRandomIndexList[$index]]) }}
                @if (!displayAnswer(module.questions[0].answers[incAnswerRandomIndexList[$index]])) {
                  <span class="placeholder">Réponse</span>
                }
              </div>
            }
          </div>
        </div>
        <span class="question-count-inc">{{ currentQuestion + "/" + module.questions[0].answers.length }}</span>
        <button class="increment-button previous-button" [ngClass]="{ 'button-disabled': currentQuestion === 1 }" (click)="previousQuestionInc()">Précédent</button>
        <button class="increment-button next-button" [ngClass]="{ 'button-disabled': currentQuestion === highestQuestion }" (click)="nextQuestionInc()">Suivant</button>
        <button class="validation-button" [ngClass]="{ 'button-disabled': selectedAnswerId === undefined }" (click)="validateQuestionInc()">Valider</button>
      }
    } @else {
      <div class="title-container">
        @if (displayModuleName()) {
          <span class="title">{{ displayModuleName() }}</span>
        } @else {
          <span class="title placeholder">Nom de l'activité</span>
        }
      </div>
      @if (!synthesisVisualisation) {
        @if (!displayQuestion(0)) {
          <p class="placeholder">Intitulé de la question</p>
        } @else {
          <p class="question">{{ displayQuestion(0) }}</p>
        }
        <div class="result-list-inc">
          @for (answer of module.questions[0].answers; track $index) {
            <div>
              <div class="result-image-container-inc centered">
                @if (answerFileUrls[0][$index]) {
                  @if (answerFileUrls[0][$index] !== "missing") {
                    <img [src]="answerFileUrls[0][$index]" alt="image" style="border-radius: 1rem" class="image-fill" />
                  } @else {
                    <i class="bi bi-card-image placeholder" style="font-size: 3rem"></i>
                  }
                } @else {
                  <p-progressSpinner></p-progressSpinner>
                }
                @if (answer.answerTranslations[0].answerExplanation) {
                  <img
                    tabindex="0"
                    aria-hidden="true"
                    src="assets/images/i_Icone_1X.png"
                    alt="image"
                    class="explanation-icon"
                    (click)="showExplanationPopupInc($index)"
                    (keypress)="showExplanationPopupInc($index)" />
                }
                <div class="result-image-name centered">
                  @if (displayAnswer(answer)) {
                    {{ displayAnswer(answer) }}
                  } @else {
                    <span class="placeholder">Réponse</span>
                  }
                </div>
              </div>
              <div class="result-selected-answer-inc centered" [ngClass]="{ 'wrong-answer': !isRightAnswerInc($index) }">
                @if (displayAnswerFromId(selectedAnswerList[$index])) {
                  {{ displayAnswerFromId(selectedAnswerList[$index]) }}
                } @else {
                  <span class="placeholder">Réponse</span>
                }
              </div>
            </div>
          }
        </div>
        @if (synthesisFileUrl !== "" || module!.moduleTranslations[0].synthesis !== "") {
          <img
            tabindex="0"
            aria-hidden="true"
            src="assets/images/Synthese_Button.png"
            alt="image"
            class="synthesis-icon"
            (click)="toggleSynthesisVisualisation()"
            (keypress)="toggleSynthesisVisualisation()" />
        }
      } @else {
        <div class="synthesis-container">
          <p>{{ displaySynthesis() }}</p>
          @if (module.mediaSynthesis) {
            @if (synthesisFileUrl !== "") {
              <img style="max-height: 100%; max-width: 100%" [src]="synthesisFileUrl" alt="image" />
            } @else {
              <p-progressSpinner></p-progressSpinner>
            }
          }
        </div>
      }
    }

    <app-information-popup [visible]="isInformationPopupVisible" [type]="module.type" (closePopupEmitter)="isInformationPopupVisible = false"> </app-information-popup>

    <app-explanation-popup
      [question]="selectedQuestion"
      [explanation]="selectedExplanation"
      [imageFileUrl]="selectedFileUrl"
      [visible]="explanationPopupVisible"
      (closePopupEmitter)="explanationPopupVisible = false">
    </app-explanation-popup>
  </div>
}
