<div class="body">
  <div class="container">
    <h1><i class="bi bi-clipboard2-check" title="Sélection du projet"></i> Sélection du projet</h1>
    <p>Cliquez ci dessous pour vous connecter au projet souhaité.</p>
    @for (project of projects; track $index) {
      <p class="project-card" [ngClass]="project.id === selectedProject ? 'selected-project' : ''" (click)="setSelectedProject(project.id)">{{ project.identificationName }}</p>
    }
    <button [disabled]="selectedProject === 0" class="button" (click)="selectProjectApi()">Sélectionner</button>
    @if (accessFromLoginPage) {
      <button class="button margin-left" (click)="logout()">Déconnexion</button>
    } @else {
      <button class="button margin-left" (click)="backToPreviousPage()">Retour</button>
    }
  </div>
</div>
