import { Component, EventEmitter, Input, Output } from "@angular/core";
import { entityType } from "app/Enumerations/entity-type.enum";
import { getPublishStateValue, publishState } from "app/Enumerations/publish-state.enum";
import { PointOfInterest } from "app/Models/point-of-interest";
import { AuthService } from "app/Services/Auth-Service/auth.service";
import { MessageService } from "primeng/api";
import { RouterLink } from "@angular/router";
import { PublicationStateComponent } from "../../../ReusableComponents/publication-state/publication-state.component";
import { CompletionStateComponent } from "../../../ReusableComponents/completion-state/completion-state.component";

@Component({
  selector: "app-point-of-interest-card",
  templateUrl: "./point-of-interest-card.component.html",
  styleUrls: ["./point-of-interest-card.component.css"],
  imports: [RouterLink, PublicationStateComponent, CompletionStateComponent],
})
export class PointOfInterestCardComponent {
  @Input() pointOfInterest!: PointOfInterest;

  @Output() deletePointOfInterestEmitter: EventEmitter<PointOfInterest> = new EventEmitter<PointOfInterest>();
  @Output() dragStartedEmitter: EventEmitter<number[]> = new EventEmitter<number[]>();

  //Enumerations
  enumPublishState = publishState;
  enumEntityType = entityType;

  constructor(
    private authService: AuthService,
    private messageService: MessageService,
  ) {}

  deletePointOfInterest() {
    this.deletePointOfInterestEmitter.emit(this.pointOfInterest);
  }

  dragStarted(event: MouseEvent) {
    event.preventDefault();
    this.dragStartedEmitter.emit([event.clientY, event.offsetY, this.pointOfInterest.order - 1]);
  }

  displayPointOfInterestState() {
    return getPublishStateValue(this.pointOfInterest.state);
  }

  getProjectName(): string {
    return this.authService.getProjectName();
  }
}
