@if (itinerary) {
  <div class="previsualisation-container">
    <img tabindex="0" aria-hidden="true" src="assets/images/Arrow_Back_Black.png" alt="image" class="arrow" (click)="backArrowClicked()" (keypress)="backArrowClicked()" />
    <div class="title-container">
      @if (displayItineraryName()) {
        <span class="title">{{ displayItineraryName() }}</span>
      } @else {
        <span class="title placeholder">Nom du parcours</span>
      }
    </div>
    <div class="point-of-interest-list">
      @for (pointOfInterest of itinerary.pointOfInterests; track $index) {
        @if (selectedLanguageId) {
          <div tabindex="0" aria-hidden="true" class="point-of-interest-item" (click)="selectPointOfInterest(pointOfInterest)" (keypress)="selectPointOfInterest(pointOfInterest)">
            <div class="horizontal-display">
              <span class="point-of-interest-index">{{ $index }}</span>
              <div>
                <div style="display: flex">
                  <img class="module-list-icon" src="assets/images/Liste_Activite_Grey.png" alt="image" />
                  <span> {{ pointOfInterest.countOfModules }} activité(s)</span>
                </div>
                <div class="point-of-interest-name">{{ displayName(pointOfInterest) }}</div>
                @if (!displayName(pointOfInterest)) {
                  <div class="point-of-interest-name placeholder">Nom de l'étape</div>
                }
                <div class="point-of-interest-details">{{ displayDetails(pointOfInterest) }}</div>
                @if (!displayDetails(pointOfInterest)) {
                  <div class="point-of-interest-details placeholder">Détails de l'étape</div>
                }
              </div>
            </div>
            <i class="bi bi-eye eye"></i>
          </div>
        }
      }
    </div>
    @if (itinerary.pointOfInterests.length === 0) {
      <p class="placeholder" style="text-align: center">Aucune étape n'a été créée dans ce parcours ce parcours</p>
    }
  </div>
}
