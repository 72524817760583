<div class="body">
  <form class="form" [formGroup]="loginForm">
    <h1 id="login-text">Connectez-vous à OHRIZON+</h1>

    <div class="form-field">
      <span class="p-float-label">
        <p-floatlabel>
          <input pInputText id="email" formControlName="email" />
          <label htmlFor="email">Adresse email</label>
        </p-floatlabel>
      </span>
    </div>
    <div class="form-field">
      <span class="p-float-label">
        <p-floatlabel>
          <p-password formControlName="password" [feedback]="false" [toggleMask]="true"></p-password>
          <label for="password">Mot de passe</label>
        </p-floatlabel>
      </span>
    </div>
    <div class="form-button">
      <div class="horizontal-display">
        <button [disabled]="isLogging" class="button" (click)="login()">Se connecter</button>
        @if (isLogging) {
          <p-progressSpinner class="spinner" [style]="{ width: '2rem', height: '2rem' }" />
        }
      </div>
      <p class="password-forgotten-text" [routerLink]="'/password-forgotten'">Mot de passe oublié ?</p>
    </div>
  </form>
</div>
