import { Component, Input } from "@angular/core";
import { Language } from "app/Models/language";
import { NgClass, UpperCasePipe } from "@angular/common";

@Component({
  selector: "app-language-state",
  templateUrl: "./language-state.component.html",
  styleUrl: "./language-state.component.css",
  imports: [NgClass, UpperCasePipe],
})
export class LanguageStateComponent {
  @Input() language!: Language;
  @Input() languageState!: boolean;
  @Input() isSmall: boolean = false;
}
