@if (module) {
  <div class="previsualisation-container" [ngClass]="{ 'black-background': mediaVisualisation }">
    @if (mediaVisualisation) {
      <img
        tabindex="0"
        aria-hidden="true"
        src="assets/images/Arrow_Back.png"
        alt="image"
        class="arrow"
        (click)="mediaVisualisation = false; selectedFileIndex = -1"
        (keypress)="mediaVisualisation = false; selectedFileIndex = -1" />
    } @else {
      <img tabindex="0" aria-hidden="true" src="assets/images/Arrow_Back_Black.png" alt="image" class="arrow" (click)="backArrowClicked()" (keypress)="backArrowClicked()" />
    }

    @if (mediaVisualisation) {
      <div class="media-visualisation-display horizontal-display-spaced">
        <div class="centered" style="width: 100%; height: 100%; margin-right: 10px; margin-left: 10px">
          @switch (module.mediaAssociations[selectedFileIndex].media.fileType) {
            @case (enumFileType.Image) {
              @if (galleryFileUrls[selectedFileIndex]) {
                <img class="image-selected" [src]="galleryFileUrls[selectedFileIndex]" alt="image" />
              } @else {
                <p-progressSpinner></p-progressSpinner>
              }
            }
            @case (enumFileType.Video) {
              <video class="image-selected" controls>
                <source [src]="galleryFileUrls[selectedFileIndex]" type="video/quicktime" />
                <source [src]="galleryFileUrls[selectedFileIndex]" type="video/mp4" />
              </video>
            }
            @case (enumFileType.Audio) {
              <audio class="image-selected" controls [src]="galleryFileUrls[selectedFileIndex]"></audio>
            }
            @case (enumFileType.Pdf) {
              <div class="vertical-display image-selected">
                <button class="button" (click)="downloadPdf()">Télécharger le pdf</button>
                <span style="color: white">Le pdf sera directement affiché dans l'application</span>
              </div>
            }
          }
        </div>
        <div class="media-visualisation-list">
          @for (media of module.mediaAssociations; track $index) {
            <div tabindex="0" aria-hidden="true" class="media-list-element centered" [ngClass]="{ 'selected-media-list-element': selectedFileIndex === $index }" (click)="setSelectedMedia($index)">
              @if (media.media.fileType === enumFileType.Image) {
                @if (galleryFileUrls[$index]) {
                  <img class="image-fill" style="border-radius: 1rem" [src]="galleryFileUrls[$index]" alt="image" />
                } @else {
                  <p-progressSpinner></p-progressSpinner>
                }
              }
            </div>
          }
        </div>
      </div>
    } @else {
      <div class="vertical-display">
        <span class="ressources-label">Ressources</span>
        <span>{{ displayModuleName() }}</span>
        @if (!displayModuleName()) {
          <span class="placeholder">Nom de l'activité</span>
        }
      </div>
      <div class="media-list scrollbar">
        @for (media of module.mediaAssociations; track $index) {
          <div tabindex="0" aria-hidden="true" class="media-container centered" (click)="setSelectedMedia($index)">
            @if (media.media.fileType === enumFileType.Image) {
              @if (galleryFileUrls[$index]) {
                <img class="image-fill" [src]="galleryFileUrls[$index]" alt="image" />
              } @else {
                <p-progressSpinner></p-progressSpinner>
              }
            }
            <div class="media-icon-container centered">
              @switch (media.media.fileType) {
                @case (enumFileType.Image) {
                  <i class="bi bi-camera-fill media-icon"></i>
                }
                @case (enumFileType.Pdf) {
                  <i class="bi bi-file-earmark-fill media-icon"></i>
                }
                @default {
                  <i class="bi bi-play-fill media-icon"></i>
                }
              }
            </div>
            <div class="media-name centered">
              @if (displayMediaName(media)) {
                {{ displayMediaName(media) }}
              } @else {
                <span style="opacity: 1; color: #ff7474" class="placeholder">Nom du média</span>
              }
            </div>
          </div>
        }
      </div>
      @if (module.mediaAssociations.length === 0) {
        <p class="placeholder" style="text-align: center">Aucun média n'a été ajouté à cette activité</p>
      }
    }
  </div>
}
