import { Location, NgClass } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Project } from "app/Models/project";
import { AuthService } from "app/Services/Auth-Service/auth.service";
import { MessageService } from "primeng/api";

@Component({
  selector: "app-project-selection-page",
  templateUrl: "./project-selection-page.component.html",
  styleUrl: "./project-selection-page.component.css",
  imports: [NgClass],
})
export class ProjectSelectionPageComponent implements OnInit {
  projects: Project[] = [];
  selectedProject: number = 0;
  accessFromLoginPage: boolean = false;

  constructor(
    private messageService: MessageService,
    private authService: AuthService,
    private router: Router,
    private location: Location,
  ) {}

  ngOnInit(): void {
    const retrievedProjects = localStorage.getItem("projects");
    if (retrievedProjects) {
      const projects = JSON.parse(retrievedProjects);
      this.projects = projects;
    }
    if (history.state.fromLoginPage) {
      this.accessFromLoginPage = true;
    }
  }

  setSelectedProject(projectId: number) {
    if (this.selectedProject !== projectId) {
      this.selectedProject = projectId;
    } else {
      this.selectedProject = 0;
    }
  }

  selectProjectApi() {
    if (this.selectedProject != 0) {
      this.authService.selectProject(this.selectedProject);
    } else {
      this.messageService.add({ severity: "warn", summary: "Aucun projet sélectionné", detail: "Sélectionner un projet pour finaliser la connexion" });
    }
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl("/login");
  }

  backToPreviousPage() {
    this.location.back();
  }
}
