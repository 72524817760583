@if (uploadInProgress && uploadedFiles.length > 0 && !visiblePopup) {
  <div class="upload-outside-popup">
    <div class="header-upload-outside-popup">
      @if (!uploadCardFolded) {
        <i tabIndex="0" aria-hidden="true" class="bi bi-chevron-down chevron" title="Replier" (click)="uploadCardFolded = true"> </i>
      } @else {
        <i tabIndex="0" aria-hidden="true" class="bi bi-chevron-up chevron" title="Déplier" (click)="uploadCardFolded = false"> </i>
      }
      <h3>Ajout en cours</h3>
      <div class="spinner-outside-popup">
        <p-progressSpinner class="spinner" [style]="{ width: '2rem', height: '2rem' }" />
      </div>
      <i tabIndex="0" aria-hidden="true" class="bi bi-x-lg btn-action-file" title="Annuler" (click)="cancelAllUploads()" (keypress)="cancelAllUploads()"> </i>
    </div>
    @if (uploadCardFolded) {
      <div class="progress-bar-header">
        <p-progressBar #progressBar [value]="sumProgressArray()"></p-progressBar>
      </div>
    }
    @if (!uploadCardFolded) {
      <div class="files-upload-outside-popup">
        @for (file of uploadedFiles; track file; let index = $index) {
          <div class="horizontal-display">
            <p class="file-name-upload-outside-popup truncate-one-line" [title]="file.name">{{ file.name }}</p>
            @if (progressArray[index] === 100) {
              <i class="bi bi-check-circle-fill ready-to-publish check-files" title="Fichier téléversé"> </i>
            }
          </div>
          <div class="progress-bar-upload-outside-popup">
            <p-progressBar #progressBar [value]="progressArray[index]"></p-progressBar>
            <i tabIndex="0" aria-hidden="true" class="bi bi-x-lg btn-action-file" title="Annuler" (click)="cancelUpload(file)" (keypress)="cancelUpload(file)"> </i>
          </div>
        }
      </div>
    }
  </div>
}
