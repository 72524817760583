import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Itinerary } from "app/Models/itinerary";
import { Language } from "app/Models/language";
import { DataService } from "app/Services/Data-Service/data.service";

@Component({
  selector: "app-itinerary-list-previsualisation",
  templateUrl: "./itinerary-list-previsualisation.component.html",
  styleUrl: "./itinerary-list-previsualisation.component.css",
})
export class ItineraryListPrevisualisationComponent implements OnInit {
  @Input() selectedLanguageId?: number;
  @Input() languageList?: Language[] = [];

  @Output() entityTypeChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() entityIdChanged: EventEmitter<number> = new EventEmitter<number>();

  itineraryList: Itinerary[] = [];

  constructor(private dataService: DataService) {}

  ngOnInit() {
    this.dataService.getItineraries().subscribe((itineraries) => {
      this.itineraryList = itineraries;
    });
  }

  selectItinerary(itinerary: Itinerary) {
    this.entityIdChanged.emit(itinerary.id);
    this.entityTypeChanged.emit("itinerary");
  }

  hasSelectedLanguage(itinerary: Itinerary): boolean {
    for (const translation of itinerary.itineraryTranslations) {
      if (translation.languageId === this.selectedLanguageId) {
        return true;
      }
    }
    return false;
  }

  displayName(itinerary: Itinerary): string | undefined {
    const selectedTranslation = itinerary.itineraryTranslations.find((t) => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.name;
    }
    return undefined;
  }

  displayDetails(itinerary: Itinerary): string | undefined {
    const selectedTranslation = itinerary.itineraryTranslations.find((t) => t.languageId === this.selectedLanguageId);
    if (selectedTranslation) {
      return selectedTranslation.details;
    }
    return undefined;
  }

  displayLanguage(): string | undefined {
    const selectedLanguage = this.languageList?.find((l) => l.id === this.selectedLanguageId);
    if (selectedLanguage) {
      return selectedLanguage.name;
    }
    return undefined;
  }

  isListEmpty(): boolean {
    for (const itinerary of this.itineraryList) {
      if (this.hasSelectedLanguage(itinerary)) {
        return false;
      }
    }
    return true;
  }
}
