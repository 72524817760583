<div class="flex justify-content-center">
  <p-dialog header="Header" [dismissableMask]="true" [modal]="true" [(visible)]="visible" [style]="{ width: '50vw' }" (onHide)="closePopup()">
    <ng-template pTemplate="header">
      @if (languageIdToDelete === 0) {
        <h2 class="text-xl font-bold">Ajouter des langues</h2>
      } @else {
        <h2 class="text-xl font-bold">Suppression d'une langue</h2>
      }
    </ng-template>

    @if (languageIdToDelete === 0) {
      @if (selectedLanguages.length === availableLanguages.length) {
        <p>Toutes les langues disponibles ont déjà été ajoutées.</p>
      } @else {
        <p>Sélectionner une ou plusieurs langues ci-dessous afin de les ajouter au parcours. Les langues que vous ajoutez seront disponibles dans les étapes et activités associées à ce parcours</p>
      }
      <div class="language">
        @for (language of availableLanguages; track $index) {
          @if (selectedLanguages.indexOf(language) === -1) {
            <p
              tabIndex="0"
              aria-hidden="true"
              (click)="selectLanguage($index)"
              (keypress)="selectLanguage($index)"
              [class.selected]="selectedLanguageArray[$index] === true"
              class="available-language">
              {{ language.name }}
            </p>
          }
        }
      </div>
    } @else {
      <p>
        Vous souhaitez supprimer la langue {{ selectedLanguages[getLanguageIndexById(languageIdToDelete)].name }}. Lorsque vous aurez sauvegardé le parcours, le contenu de la langue
        {{ selectedLanguages[getLanguageIndexById(languageIdToDelete)].name }} sera supprimé dans les étapes associées.
      </p>
    }

    <ng-template pTemplate="footer">
      @if (selectedLanguages.length !== availableLanguages.length || languageIdToDelete !== 0) {
        <button class="button confirm-action-button" (click)="changeLanguageConfiguration()">
          <span title="Confirmer">Confirmer</span>
        </button>
      }
      <button class="button" (click)="closePopup()" title="Annuler l'action">Annuler</button>
    </ng-template>
  </p-dialog>
</div>
