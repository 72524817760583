import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Language } from "app/Models/language";
import { MessageService, PrimeTemplate } from "primeng/api";
import { Dialog } from "primeng/dialog";

@Component({
  selector: "app-add-language-popup",
  templateUrl: "./add-language-popup.component.html",
  styleUrl: "./add-language-popup.component.css",
  imports: [Dialog, PrimeTemplate],
})
export class AddLanguagePopupComponent {
  @Input() visible: boolean = false;
  @Input() availableLanguages: Language[] = [];
  @Input() selectedLanguages: Language[] = [];
  @Input() languageIdToDelete: number = 0;

  @Output() confirmActionEmitter: EventEmitter<number[]> = new EventEmitter<number[]>();

  selectedLanguageArray: boolean[] = [];

  constructor(private messageService: MessageService) {}

  selectLanguage(index: number) {
    this.selectedLanguageArray[index] = !this.selectedLanguageArray[index];
  }

  changeLanguageConfiguration() {
    if (this.selectedLanguageArray.length !== 0) {
      const newLanguages: number[] = [];

      this.selectedLanguageArray.forEach((isSelected, index) => {
        if (isSelected) {
          newLanguages.push(this.availableLanguages[index].id);
        }
      });

      this.confirmActionEmitter.emit(newLanguages);
      this.selectedLanguageArray = [];
    } else if (this.languageIdToDelete !== 0) {
      this.confirmActionEmitter.emit([this.languageIdToDelete]);
    } else {
      this.messageService.clear();
      this.messageService.add({ severity: "warn", summary: "Sélectionner une langue", detail: "Aucune langue n'est sélectionnée" });
    }
  }

  getLanguageIndexById(languageId: number): number {
    return this.selectedLanguages.findIndex((l) => l.id == languageId);
  }

  deleteLanguage() {
    this.confirmActionEmitter.emit([this.languageIdToDelete]);
  }

  closePopup() {
    this.confirmActionEmitter.emit([]);
  }
}
